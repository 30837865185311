import { Transition } from 'framer-motion';

export const DEG2RAD = 0.0174533;

export const NAV_APPEAR_DURATION = 0.3;
export const NAV_LINKS_APPEAR_DURATION = 1;

export const links = {
  home: `/`,
  about: `/about`,

  allProjects: `/projects`,
  project: (slug: string) => `/projects/${slug}`,
  projectArticle: (projectSlug: string, articleSlug: string) =>
    `/projects/${projectSlug}/${articleSlug}`,

  allArticles: `/articles`,
  article: (slug: string) => `/articles/${slug}`,
  tag: (tag: string) => `/articles/tags/${tag}`,

  contact: `/contact`
};

export const SHARED_ELEMENT_TRANSITION: Transition = {
  type: 'spring',
  duration: 0.5,
  bounce: 0.3
};
