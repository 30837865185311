import React, { useState } from 'react';
import { motion, Variants } from 'framer-motion';
import Blob from './Blob';
import { button, blob, lines, line } from './NavigationButton.module.css';

const containerVariants: Variants = {
  idle: {
    // transition: {
    //   staggerChildren: 0.5
    // }
  },
  hover: {
    // transform: 'scale(1)',
    transition: {
      staggerChildren: 0.1
    }
  }
};

const lineVariants: Variants = {
  idle: {
    scale: 1
  },
  hover: {
    scale: 1.2,
    transition: {
      repeat: Infinity,
      repeatDelay: 0.15,
      repeatType: 'reverse'
    }
  }
};

interface NavigationButtonProps {
  onClick: () => void;
}

const NavigationButton = ({ onClick }: NavigationButtonProps) => {
  const [isHovering, setHovering] = useState(false);

  const speed = isHovering ? 0.015 : 0.002;
  const state = isHovering ? 'hover' : 'idle';

  return (
    <button
      type="button"
      aria-label="Open navigation"
      aria-haspopup="true"
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={onClick}
      className={button}
    >
      <Blob className={blob} speed={speed} />
      <motion.div
        className={lines}
        variants={containerVariants}
        initial="idle"
        animate={state}
      >
        <motion.div variants={lineVariants} className={line} />
        <motion.div variants={lineVariants} className={line} />
        <motion.div variants={lineVariants} className={line} />
      </motion.div>
    </button>
  );
};

export default NavigationButton;
