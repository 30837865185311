import React from 'react';
import Button from '../../../common/Button/Button';

interface CloseButtonProps {
  onClick: () => void;
}

const CloseButton = ({ onClick }: CloseButtonProps) => {
  return <Button onClick={onClick}>Go back</Button>;
};

export default CloseButton;
