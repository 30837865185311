import React, { useState, useRef, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { globalHistory } from '@reach/router';
import NavigationButton from './NavigationButton/NavigationButton';
import { button, navigation, content, curve } from './Navigation.module.css';
import NavigationContent from './NavigationContent/NavigationContent';
import { NAV_APPEAR_DURATION } from '../../../const';
import { useMainStore } from '../../../store/mainStore';

// so, tailwind strips out unused classes.
// in "src/content/data.ts" I have used a class
// to add some margin to the underline.
// however, tailwind will look only in the
// components and pages folder, so it will strip
// out my class. in order to lie to tailwind and make
// it think that it is used (it actually is lol)
// I just need to use it somewhere in a component
// so here it is: mt-1

const CURVE_WIDTH = 30;

const Navigation = () => {
  const setNavigationOpen = useMainStore((state) => state.setNavigationOpen);
  const navRef = useRef<HTMLDivElement>(null);
  const openRef = useRef(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = globalHistory.listen(() => {
      setOpen(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    openRef.current = open;
    if (!open) {
      setNavigationOpen(openRef.current);
      document.body.style.paddingRight = '0px';
      document.body.classList.remove('overflow-hidden');
    } else {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;

      navRef.current?.focus();
      // prevent content from shifting when opening navbar
      // because the body's scrollbar disapepars
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.body.classList.add('overflow-hidden');
    }
  }, [open]);

  return (
    <>
      <div className={button}>
        <NavigationButton onClick={() => setOpen(!open)} />
      </div>

      <AnimatePresence>
        {open && (
          <motion.div
            key="nav"
            initial={{ right: '-110%' }}
            animate={{
              right: 0,
              transition: { duration: NAV_APPEAR_DURATION }
            }}
            exit={{ right: '-110%' }}
            onAnimationComplete={() => {
              if (openRef.current) setNavigationOpen(openRef.current);
            }}
            className={`${navigation} h-full fixed top-0 flex`}
          >
            <svg
              width={CURVE_WIDTH}
              className={curve}
              viewBox="1352.805 -272.159 85.054 1440.031"
              preserveAspectRatio="none"
            >
              <path
                fill="var(--white)"
                d="M 669.45 460.656 L 733.974 454.687 C 793.982 448.987 913.995 438.987 1034.01 427.987 C 1154.025 416.987 1274.039 406.987 1394.025 417.287 C 1514.01 427.987 1633.966 459.987 1753.959 465.287 C 1873.951 470.987 1993.982 448.987 2053.996 438.687 L 2110.079 427.987 L 2109.86 481.829 L 2053.791 481.829 C 1993.791 481.829 1873.791 481.829 1753.791 481.829 C 1633.791 481.829 1513.791 481.829 1393.791 481.829 C 1273.791 481.829 1153.791 481.829 1033.791 481.829 C 913.791 481.829 793.791 481.829 733.791 481.829 L 669.275 482.498 L 669.45 460.656 Z"
                transform="matrix(0, -1, 1, 0, 941.940155, 1837.413971)"
              />
              <path
                fill="var(--nav-background)"
                d="M 679.13 456.73 L 743.654 450.761 C 803.662 445.061 923.675 435.061 1043.69 424.061 C 1163.705 413.061 1283.719 403.061 1403.705 413.361 C 1523.69 424.061 1643.646 456.061 1763.639 461.361 C 1883.631 467.061 2003.662 445.061 2063.676 434.761 L 2119.759 424.061 L 2119.54 477.903 L 2063.471 477.903 C 2003.471 477.903 1883.471 477.903 1763.471 477.903 C 1643.471 477.903 1523.471 477.903 1403.471 477.903 C 1283.471 477.903 1163.471 477.903 1043.471 477.903 C 923.471 477.903 803.471 477.903 743.471 477.903 L 678.955 478.572 L 679.13 456.73 Z"
                transform="matrix(0, -1, 1, 0, 960.10408, 1847.056931)"
              />
            </svg>
            <div className={`${content} relative overflow-y-scroll w-screen`}>
              <NavigationContent ref={navRef} onClose={() => setOpen(false)} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navigation;
