import create from 'zustand';

interface MainStore {
  navigationOpen: boolean;
  setNavigationOpen: (newValue: boolean) => void;
}

export const useMainStore = create<MainStore>((set) => ({
  navigationOpen: false,
  setNavigationOpen: (newValue: boolean) =>
    set(() => ({ navigationOpen: newValue }))
}));
