import create from 'zustand';
import { KeyValue } from '../types/types';

interface LoadingComponent {
  weight: number;
  progress: number;
}

interface LoadingStore {
  loadingComponents: KeyValue<LoadingComponent>;
  fullyLoaded: boolean;
  markFullyLoaded: () => void;
  setProgress: (component: string, progress: number, weight: number) => void;
  reset: () => void;
}

export const useLoadingStore = create<LoadingStore>((set) => ({
  loadingComponents: {},
  fullyLoaded: false,
  setProgress(component, progress, weight) {
    set((state) => ({
      loadingComponents: {
        ...state.loadingComponents,
        [component]: {
          weight,
          progress
        }
      }
    }));
  },
  markFullyLoaded() {
    set({ fullyLoaded: true });
  },
  reset() {
    set({ loadingComponents: {} });
  }
}));
