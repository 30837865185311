import React from 'react';
import LoadingCurtain from '../LoadingCurtain/LoadingCurtain';
import Navigation from '../Navigation/Navigation';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <>
      <Navigation />
      <LoadingCurtain />
      {children}
    </>
  );
};

export default MainLayout;
