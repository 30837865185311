import React from 'react';
import { motion } from 'framer-motion';
import { underline } from './NavigationUnderline.module.css';

interface NavigationUnderlineProps {
  opacity?: number;
  className?: string;
}

const NavigationUnderline = ({
  opacity = 1,
  className = ''
}: NavigationUnderlineProps) => {
  return (
    <motion.span
      layoutId="nav-underline"
      animate={{ opacity }}
      className={`${underline} ${className} w-full rounded-sm block absolute`}
      transition={{ type: 'spring', duration: 0.5 }}
    />
  );
};

export default NavigationUnderline;
