import { ComponentType } from 'react';
import EmailIcon from '../components/common/icons/Email';
import GitHubIcon from '../components/common/icons/GitHub';
import LinkedInIcon from '../components/common/icons/LinkedIn';
import NPMIcon from '../components/common/icons/NPM';
import TwitterIcon from '../components/common/icons/Twitter';
import { links } from '../const';

export const contactLinks = {
  email: 'm.n.koparanov@gmail.com',
  linkedin: 'https://linkedin.com/in/martin-koparanov/',
  github: 'https://github.com/onestopjs',
  npm: 'https://npmjs.com/~onestopjs',
  twitter: 'https://twitter.com/onestopjs'
};

export const age = Math.floor(
  (Date.now() - new Date('1999-06-30').getTime()) / 31536000000
);

export const yearsOfExperience = Math.floor(
  (Date.now() - new Date('2016-01-01').getTime()) / 31536000000
);

interface NavLink {
  text: string;
  to: string;
}

interface ContactLink {
  icon: ComponentType;
  text: string;
  href: string;
  underlineClassName?: string;
}

export const navLinks: NavLink[] = [
  { text: 'Intro', to: links.home },
  { text: 'About me', to: links.about },
  { text: 'Projects', to: links.allProjects },
  { text: 'Articles', to: links.allArticles },
  { text: 'Contact', to: links.contact }
];

export const contacts: ContactLink[] = [
  {
    icon: EmailIcon,
    text: contactLinks.email,
    href: `mailto:${contactLinks.email}`
  },
  {
    icon: LinkedInIcon,
    text: 'Martin Koparanov',
    href: contactLinks.linkedin,
    underlineClassName: 'mt-1'
  },
  {
    icon: GitHubIcon,
    text: 'onestopjs',
    href: contactLinks.github,
    underlineClassName: 'mt-1'
  },
  {
    icon: NPMIcon,
    text: 'onestopjs',
    href: contactLinks.npm
  },
  {
    icon: TwitterIcon,
    text: 'onestopjs',
    href: contactLinks.twitter
  }
];
