import React from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import { link, revealer, text } from './NavigationLink.module.css';
import NavigationUnderline from './NavigationUnderline';
import { linkVariants } from './common';

interface NavigationLinkProps {
  to: string;
  focused: boolean;
  children: React.ReactNode;
}

const NavigationLink = ({
  to,
  focused,

  children
}: NavigationLinkProps) => {
  return (
    <motion.li
      role="none"
      variants={linkVariants}
      className={`${link} relative font-title whitespace-nowrap`}
    >
      <Link role="menuitem" to={to}>
        {children}
      </Link>

      {focused && <NavigationUnderline />}

      <span className={revealer}>
        <span className={text}>{children}</span>
      </span>
    </motion.li>
  );
};

export default NavigationLink;
