import React from 'react';
import { motion } from 'framer-motion';
import { box, bar, pulsar } from './LoadingBar.module.css';

interface LoadingBarProps {
  progress: number;
}

const LoadingBar = ({ progress }: LoadingBarProps) => {
  return (
    <div>
      <div className={`${box} w-full overflow-hidden rounded-sm relative`}>
        <div className={`${pulsar} mx-auto absolute`} />
        <motion.div
          initial={{ width: `${progress}%` }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 1, type: 'tween' }}
          className={`${bar} mx-auto`}
        />
      </div>
      <p className="text-center my-3">{`${Math.round(progress)}%`}</p>
    </div>
  );
};

export default LoadingBar;
