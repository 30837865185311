// https://github.com/georgedoescode/splinejs/blob/main/spline.js

export type Point = {
  x: number;
  y: number;
  // we need to keep a reference to the point's original point for when we modulate the values later
  originX: number;
  originY: number;
  // more on this in a moment!
  noiseOffsetX: number;
  noiseOffsetY: number;
};

export const createPointsInCircle = (pointsAmount: number, radius: number) => {
  const points: Point[] = [];
  // used to equally space each point around the circle
  const angleStep = (Math.PI * 2) / pointsAmount;
  // the radius of the circle

  for (let i = 1; i <= pointsAmount; i++) {
    // x & y coordinates of the current point
    const theta = i * angleStep;

    const x = 100 + Math.cos(theta) * radius;
    const y = 100 + Math.sin(theta) * radius;

    // store the point's position
    points.push({
      x: x,
      y: y,
      // we need to keep a reference to the point's original point for when we modulate the values later
      originX: x,
      originY: y,
      // more on this in a moment!
      noiseOffsetX: Math.random() * 1000,
      noiseOffsetY: Math.random() * 1000
    });
  }

  return points;
};

const formatPoints = (rawPoints: Point[], close = false) => {
  const points: [number, number][] = rawPoints.map(({ x, y }) => [x, y]);
  // so that coords can be passed as objects or arrays

  if (close) {
    const lastPoint = points[points.length - 1];
    const secondToLastPoint = points[points.length - 2];

    const firstPoint = points[0];
    const secondPoint = points[1];

    points.unshift(lastPoint);
    points.unshift(secondToLastPoint);

    points.push(firstPoint);
    points.push(secondPoint);
  }

  return points.flat();
};

const spline = (rawPoints: Point[] = [], tension = 1, close = false) => {
  const points = formatPoints(rawPoints, close);

  const size = points.length;
  const last = size - 4;

  const startPointX = close ? points[2] : points[0];
  const startPointY = close ? points[3] : points[1];

  let path = 'M' + [startPointX, startPointY];

  const startIteration = close ? 2 : 0;
  const maxIteration = close ? size - 4 : size - 2;
  const inc = 2;

  for (let i = startIteration; i < maxIteration; i += inc) {
    const x0 = i ? points[i - 2] : points[0];
    const y0 = i ? points[i - 1] : points[1];

    const x1 = points[i + 0];
    const y1 = points[i + 1];

    const x2 = points[i + 2];
    const y2 = points[i + 3];

    const x3 = i !== last ? points[i + 4] : x2;
    const y3 = i !== last ? points[i + 5] : y2;

    const cp1x = x1 + ((x2 - x0) / 6) * tension;
    const cp1y = y1 + ((y2 - y0) / 6) * tension;

    const cp2x = x2 - ((x3 - x1) / 6) * tension;
    const cp2y = y2 - ((y3 - y1) / 6) * tension;

    path += 'C' + [cp1x, cp1y, cp2x, cp2y, x2, y2];
  }

  return path;
};

export default spline;
