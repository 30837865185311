import React, { useState } from 'react';
import { motion, Variants } from 'framer-motion';
import CloseButton from './CloseButton';
import {
  headline,
  logo,
  closeButton,
  mainRow,
  col2
} from '../Navigation.module.css';
import NavigationLink from './NavigationLink';
import {
  NAV_APPEAR_DURATION,
  NAV_LINKS_APPEAR_DURATION
} from '../../../../const';
import { forwardRef } from 'react';
import NavigationUnderline from './NavigationUnderline';
import NavigationContactLink from './NavigationContactLink';
import { contacts, navLinks } from '../../../../content/data';

interface NavigationContentProps {
  onClose: () => void;
}

const navVariants: Variants = {
  hidden: { opacity: 1, translateY: 0 },
  show: {
    opacity: 1,
    translateY: 0,
    transition: {
      delayChildren: NAV_APPEAR_DURATION,
      duration: NAV_LINKS_APPEAR_DURATION,
      staggerChildren: 0.05
    }
  }
};

const socialsVariants: Variants = {
  ...navVariants,
  show: {
    ...navVariants.show,
    transition: {
      // @ts-ignore wtf xDDDDDDDDDD
      ...navVariants.show.transition,
      delayChildren: NAV_APPEAR_DURATION + 0.2
    }
  }
};

const NavigationContent = forwardRef<HTMLDivElement, NavigationContentProps>(
  ({ onClose }, ref) => {
    const [focused, setFocused] = useState<string | null>(null);
    return (
      <nav ref={ref} className="py-5 px-5 sm:px-14">
        <div className={`${headline} flex flex-wrap py-3 justify-between`}>
          <div className="relative inline-block">
            <p className={`${logo} font-title`}>[onestopjs]</p>
            {focused === null && (
              <NavigationUnderline opacity={0.8} className="mt-2" />
            )}
          </div>
          <div className={closeButton}>
            <CloseButton onClick={onClose} />
          </div>
        </div>
        <div className={`${mainRow} flex justify-between mt-8`}>
          <motion.ul
            className="flex items-start flex-col"
            initial="hidden"
            animate="show"
            variants={navVariants}
            role="menu"
          >
            {navLinks.map((link) => (
              <div
                key={link.to}
                className="pb-10"
                onMouseOver={() => setFocused(link.to)}
                onMouseLeave={() => setFocused(null)}
              >
                <NavigationLink focused={focused === link.to} to={link.to}>
                  {link.text}
                </NavigationLink>
              </div>
            ))}
          </motion.ul>

          <div className={`${col2} flex justify-center`}>
            <motion.ul
              className="flex items-start flex-col"
              initial="hidden"
              animate="show"
              variants={socialsVariants}
              role="menu"
            >
              {contacts.map(
                ({ icon: Icon, text, href, underlineClassName }) => (
                  <NavigationContactLink
                    key={href}
                    onMouseOver={() => setFocused(href)}
                    onMouseLeave={() => setFocused(null)}
                    focused={focused === href}
                    href={href}
                    underlineClassName={underlineClassName}
                  >
                    <Icon />
                    {text}
                  </NavigationContactLink>
                )
              )}
            </motion.ul>
          </div>
        </div>
      </nav>
    );
  }
);

export default NavigationContent;
