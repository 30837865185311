import React, { useEffect, useRef } from 'react';
import { createNoise2D } from 'simplex-noise';
import spline, { createPointsInCircle } from '../../../../utils/animatedCurves';
import mapRange from '../../../../utils/mapRange';

interface BlobProps {
  className?: string;
  speed: number;
}

const DISTANCE = 10;

const Blob = ({ className = '', speed = 0.001 }: BlobProps) => {
  const pathRef = useRef<SVGPathElement>(null);
  const noiseRef = useRef<number>(speed);

  useEffect(() => {
    noiseRef.current = speed;
  }, [speed]);

  useEffect(() => {
    const points = createPointsInCircle(6, 75);
    const noise = createNoise2D();

    const animate = () => {
      // fuck you it won't be undefined
      // fucking shit sometimes it is undefined :@ idk why and dont care
      (pathRef.current as SVGPathElement)?.setAttribute(
        'd',
        spline(points, 1, true)
      );

      // for every point...
      for (let i = 0; i < points.length; i++) {
        const point = points[i];

        // return a pseudo random value between -1 / 1 based on this point's current x, y positions in "time"
        const nX = noise(point.noiseOffsetX, point.noiseOffsetX);
        const nY = noise(point.noiseOffsetY, point.noiseOffsetY);
        // map this noise value to a new value, somewhere between it's original location -20 and it's original location + 20
        const x = mapRange(
          nX,
          -1,
          1,
          point.originX - DISTANCE,
          point.originX + DISTANCE
        );
        const y = mapRange(
          nY,
          -1,
          1,
          point.originY - DISTANCE,
          point.originY + DISTANCE
        );

        // update the point's current coordinates
        point.x = x;
        point.y = y;

        // progress the point's x, y values through "time"
        point.noiseOffsetX += noiseRef.current;
        point.noiseOffsetY += noiseRef.current;
      }

      requestAnimationFrame(animate);
    };

    animate();
  }, []);
  return (
    <svg className={className} viewBox="0 0 200 200" width={100} height={100}>
      <path ref={pathRef} d="" fill="var(--white)"></path>
    </svg>
  );
};

export default Blob;
