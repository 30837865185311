import { AnimatePresence, motion, Variants } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';
import { useLoadingStore } from '../../../store/loadingStore';
import LoadingBar from '../../common/LoadingBar/LoadingBar';
import {
  wrapper,
  content,
  curtain,
  title,
  subTitle,
  progressBar
} from './LoadingCurtain.module.css';

const FONTS_TO_CHECK = ['16px Space Mono', '16px Mulish'];

const LoadingCurtain = () => {
  const [minDurationElapsed, setMinDurationElapsed] = useState(false);
  const { loadingComponents, fullyLoaded, markFullyLoaded, setProgress } =
    useLoadingStore((state) => state);

  useEffect(() => {
    const timeout1 = setTimeout(() => {
      setMinDurationElapsed(true);
    }, 1600);

    return () => {
      clearTimeout(timeout1);
    };
  }, []);

  useEffect(() => {
    const check = () => {
      let notAllLoaded = false;
      const isFirefox =
        navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      for (const font of FONTS_TO_CHECK) {
        const loaded = isFirefox || document.fonts.check(font);
        setProgress(font, loaded ? 100 : 0, 1);
        if (!loaded) notAllLoaded = true;
      }

      if (notAllLoaded) {
        requestAnimationFrame(check);
      }
    };

    check();
  }, []);

  const loadingProgress = useMemo(() => {
    const values = Object.values(loadingComponents);
    if (values.length === 0) return 0;
    let totalProgress = 0;
    let totalWeight = 0;

    for (const { progress, weight } of values) {
      totalProgress += progress * weight;
      totalWeight += weight;
    }

    return totalProgress / totalWeight;
  }, [loadingComponents]);

  const showCurtain = !minDurationElapsed || loadingProgress < 100;
  useEffect(() => {
    // to prevent showing curtain after it has been removed once
    if (!showCurtain) markFullyLoaded();
  }, [showCurtain]);

  return (
    <AnimatePresence>
      {fullyLoaded ? null : showCurtain ? (
        <motion.div
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'tween', duration: 0.6 }}
          className={`fixed w-screen h-screen ${curtain}`}
        >
          <div className={`${wrapper} relative`}>
            <div className={`${content} px-5 absolute`}>
              <div className="overflow-hidden py-2">
                <p className={`${title} font-title text-center`}>onestopjs</p>
              </div>
              <div className="overflow-hidden mb-8">
                <p className={`${subTitle} text-center text-sm`}>
                  by Martin Koparanov
                </p>
              </div>

              <div className={`overflow-hidden mx-auto ${progressBar}`}>
                <LoadingBar progress={loadingProgress} />
              </div>
            </div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};

export default LoadingCurtain;
