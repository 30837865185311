import React from 'react';
import { motion } from 'framer-motion';
import { linkVariants } from './common';
import { link } from './NavigationContactLink.module.css';
import NavigationUnderline from './NavigationUnderline';

interface NavigationContactLinkProps {
  href: string;
  children: React.ReactNode;
  focused: boolean;

  underlineClassName?: string;

  onMouseOver: () => void;
  onMouseLeave: () => void;
}

const NavigationContactLink = ({
  href,
  children,
  focused,
  underlineClassName = '',
  onMouseOver,
  onMouseLeave
}: NavigationContactLinkProps) => {
  return (
    <motion.li
      className="relative pb-4"
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      variants={linkVariants}
    >
      <a className={`${link} flex items-center`} target="_blank" href={href}>
        {children}
      </a>

      {focused && <NavigationUnderline className={underlineClassName} />}
    </motion.li>
  );
};

export default NavigationContactLink;
