import React from 'react';
import { button } from './Button.module.css';

interface ButtonProps {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}

const Button = ({ className = '', onClick, children }: ButtonProps) => {
  return (
    <button
      className={`${button} ${className} rounded-sm px-4 py-2`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
