import { Variants } from 'framer-motion';

export const linkVariants: Variants = {
  hidden: { opacity: 0, x: 50 },
  show: {
    opacity: 1,
    x: 0,
    transition: { type: 'tween', duration: 0.2 }
  }
};
